import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from "../../redux/store/hooks";
import { useTranslation } from 'react-i18next';
import { Spinner } from '@cd/sdds-common-components-react';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { _updateSelectedUserAction } from "../../redux/store/faultGuideManagement";
import './FaultGuideForm.scss';
import { InfoIconWithTooltip } from '@cd/sdds-common-components-react/lib/esm/components/assets';


type FaultGuideFormProps = {
    faultGuide: TsGuideDto,
    userAction: FormStateType,
    getFieldValues: Function,
    onCancel: Function,
    onSave: Function,
    onDelete: Function,
    validationError: TextfieldErrorType,
    tsFunctions: Array<FunctionType>,
    functionIdsToNames: string,
    tsFunctionNames: string,
    developerAccess: boolean,
}

type EmailProps = {
    email: string,
    subject: string,
    children: any,
    body: any,
}

const Mailto = (props: EmailProps) => {
    const { email, subject, body, children } = props;
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

    return <a className='sdds-btn sdds-btn-secondary sdds-btn-md actionButton' href={`mailto:${email}${params}`}>{children}</a>;
};


const FaultGuideForm = (props: FaultGuideFormProps) => {

    const { faultGuide, onCancel, onSave, onDelete, validationError, tsFunctions, functionIdsToNames, tsFunctionNames, developerAccess } = props;
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showSubmitDialog, setShowSubmitDialog] = useState<boolean>(false);
    const [translated, setTranslated] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const faultGuideManagement: FaultGuideManagementState = useAppSelector<FaultGuideManagementState>(state => state.faultGuideManagement)
    const { t } = useTranslation();

    useEffect(() => {
        if (faultGuide.status === 'Translated')
            setTranslated(true)
        else
            setTranslated(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, [faultGuide.id]);

    /* eslint-disable no-useless-concat */
    const emailbody = `Hi ,` + '\r\n' + '\r\n' + `Please make the following updates for the fault guide with fault code ${faultGuide?.id} : ` + '\r\n' + '\r\n' + `(e.g.Field Name= description,action etc.)` + '\r\n' + '\r\n' + ` [Enter Field Name] - [Enter updated value] ` + '\r\n' + ` [Enter Field Name] - [Enter updated value] ` + '\r\n' + ` [Enter Field Name] - [Enter updated value] ` + '\r\n' + `.` + '\r\n' + `.` + '\r\n' + `.` + '\r\n' + `[Enter any additional information]`

    return (<>

        {faultGuideManagement.userAction !== 'hide' &&
            <div className='fault-guide-form'>
                <div className='form-top-bar'>
                    <div className='sdds-headline-05'>{t('TS_temp:ServiceTroubleshootingFaultGuide')}</div>
                    <div className='secondary-action-buttons'>
                        {(developerAccess || faultGuide.status === 'Draft') &&
                            <>
                                <button disabled={faultGuideManagement.userAction === 'create' || faultGuideManagement.userAction === 'edit'} className='sdds-btn sdds-btn-secondary sdds-btn-sm actionButton' title={t("TS_core:EditButton")} onClick={() => dispatch(_updateSelectedUserAction('edit'))}>
                                    {t('TS_core:Edit')}</button>
                                <button disabled={faultGuideManagement.userAction === 'create'} className='sdds-btn sdds-btn-secondary sdds-btn-sm actionButton' title={t('TS_core:Deregister')} onClick={() => setShowDeleteDialog(true)}>
                                    {t("TS_core:Deregister")}</button>
                            </>}
                        {/** Admin requests for update to dev team through email  **/}
                        {faultGuide.status !== 'Draft' && faultGuideManagement.userAction === 'view' && !developerAccess &&
                            <Mailto email="cstt.support@scania.com" subject={`Fault guide UPDATE request for ${faultGuide?.id}`} body={emailbody}>
                                {t("TS_core:RequestUpdate")}
                            </Mailto>
                        }
                    </div>
                </div>

                <div className='form-fields'>
                    <div className='form-field'>
                        <div className='form-label sdds-headline-07'>{t('TS_core:FaultCode')}</div>
                        <div className='form-value'>
                            {faultGuideManagement.userAction === 'create' && <sdds-textfield id='code' autofocus={true} type="text" size="md" placeholder={t("TS_core:EnterFaultCode")} value={''} state={validationError} nominwidth>
                                <span slot="sdds-helper">{t("TS_core:FaultCodeHelperText")}</span>
                            </sdds-textfield>}
                            {faultGuideManagement.userAction === 'edit' && <sdds-textfield readonly id='code' type="text" size="md" placeholder={faultGuideManagement.selectedGuideDto?.id} value={faultGuideManagement.selectedGuideDto?.id} nominwidth></sdds-textfield>}
                            {faultGuideManagement.userAction === 'view' && <div className='view-state'>{faultGuideManagement.selectedGuideDto?.id}</div>}
                        </div>
                    </div>
                    {/* Replace sdds-textfield with sdds-textarea for the next 3 fields (description, cause, action) once their values become readable --wait for Tegel team to fix this-- */}
                    <div className='form-field'>
                        <div className='form-label sdds-headline-07'>{t('TS_core:Description')}</div>
                        <div className='form-value'>
                            {faultGuideManagement.userAction === 'create' && <sdds-textfield id='description' size="md" placeholder={t("TS_core:EnterDescription")} value={''}></sdds-textfield>}
                            {faultGuideManagement.userAction === 'edit' && <sdds-textfield id='description' type="text" size="md" placeholder={faultGuideManagement.selectedGuideDto?.description} value={faultGuideManagement.selectedGuideDto?.description}></sdds-textfield>}
                            {faultGuideManagement.userAction === 'view' && <div className='translatable'>
                                <div className='view-state'>{faultGuideManagement.selectedGuideDto?.description}</div>
                                {faultGuideManagement.selectedGuideDto?.description && faultGuide.description === faultGuideManagement.selectedGuideDto?.description && faultGuide.status === 'SubmittedForTranslation' &&
                                    <>
                                        <div className='translation-pending-icon'>
                                            <InfoIconWithTooltip  tooltipText={t("TS_core:TranslationPending")} uniqueId={`enter-description-translation-pending`} iconClassName={"tds-text-blue-500 tds-u"} />
                                        </div>                                       
                                    </>
                                }
                            </div>}
                        </div>
                    </div>
                    <div className='form-field'>
                        <div className='form-label sdds-headline-07'>{t('TS_core:Cause')}</div>
                        <div className='form-value'>
                            {faultGuideManagement.userAction === 'create' && <sdds-textfield id="cause" type="text" size="md" placeholder={t("TS_core:EnterCausesForFaultCode")} value={''}></sdds-textfield>}
                            {faultGuideManagement.userAction === 'edit' && <sdds-textfield id="cause" type="text" size="md" placeholder={faultGuideManagement.selectedGuideDto?.cause} value={faultGuideManagement.selectedGuideDto?.cause}></sdds-textfield>}
                            {faultGuideManagement.userAction === 'view' && <div className='translatable'>
                                <div className='view-state'>{faultGuideManagement.selectedGuideDto?.cause}</div>
                                {faultGuideManagement.selectedGuideDto?.cause && faultGuide.cause === faultGuideManagement.selectedGuideDto?.cause && faultGuide.status === 'SubmittedForTranslation' &&
                                    <>
                                        <div className='translation-pending-icon'>
                                            <InfoIconWithTooltip  tooltipText={t("TS_core:TranslationPending")} uniqueId={`enter-causes-for-fault-code-translation-pending`} iconClassName={"tds-text-blue-500 tds-u"} />
                                        </div>   
                                    </>
                                }
                            </div>}
                        </div>
                    </div>
                    <div className='form-field'>
                        <div className='form-label sdds-headline-07'>{t("TS_core:Action")}</div>
                        <div className='form-value'>
                            {faultGuideManagement.userAction === 'create' && <sdds-textfield id='action' type="text" size="md" placeholder={t("TS_core:EnterPossibleActionsForFaultCode")} value={''}></sdds-textfield>}
                            {faultGuideManagement.userAction === 'edit' && <sdds-textfield id='action' type="text" size="md" placeholder={faultGuideManagement.selectedGuideDto?.action} value={faultGuideManagement.selectedGuideDto?.action}></sdds-textfield>}
                            {faultGuideManagement.userAction === 'view' && <div className='translatable'>
                                <div className='view-state'>{faultGuideManagement.selectedGuideDto?.action}</div>
                                {faultGuideManagement.selectedGuideDto?.action && faultGuide.action === faultGuideManagement.selectedGuideDto?.action && faultGuide.status === 'SubmittedForTranslation' &&
                                    <>
                                        <div className='translation-pending-icon'> 
                                            <InfoIconWithTooltip  tooltipText={t("TS_core:TranslationPending")} uniqueId={`enter-possible-actions-for-fault-code-translation-pending`} iconClassName={"tds-text-blue-500 tds-u"} />
                                        </div>
                                        
                                    </>
                                }
                            </div>}
                        </div>
                    </div>
                    <div className='form-field'>
                        <div className='form-label sdds-headline-07'>{t("TS_core:Functions")}</div>
                        <div className='form-value drop'>
                            {faultGuideManagement.userAction === 'create' && tsFunctions.length > 0 &&
                                <sdds-dropdown
                                    id='multi-select-demo'
                                    placeholder={t("TS_core:SelectFunctions")} type="multiselect">
                                    {tsFunctions.map((func) => {
                                        return <sdds-dropdown-option key={func.value} value={func.value}>{func.label}</sdds-dropdown-option>
                                    })}
                                </sdds-dropdown>}
                            {faultGuideManagement.userAction === 'edit' && !faultGuideManagement.tsFunctionsApiLoading &&
                                <sdds-dropdown id='multi-select-demo' type="multiselect" placeholder={t("TS_core:SelectFunctions")} default-option={tsFunctionNames}>
                                    {tsFunctions.map((func) => {
                                        return <sdds-dropdown-option key={func.value} value={func.value}>{func.label}</sdds-dropdown-option>
                                    })}
                                </sdds-dropdown>}
                            {faultGuideManagement.userAction === 'edit' && faultGuideManagement.tsFunctionsApiLoading && <div className='view-state'><Spinner size='sm' /></div>}
                            {faultGuideManagement.userAction === 'view' && <div className='view-state'>{!faultGuideManagement.tsFunctionsApiLoading && (functionIdsToNames || t('N/A'))}  {faultGuideManagement.tsFunctionsApiLoading && <Spinner size='sm' />} </div>}
                        </div>
                    </div>
                    {developerAccess &&
                        <div className='form-field'>
                            <div className="sdds-checkbox-item">
                                <input className="sdds-form-input" type="checkbox" id="cb-option-1" onChange={(e) => { setTranslated(!translated); }} checked={translated} disabled={faultGuideManagement.userAction === 'view'} />
                                <label htmlFor='cb-option-1' className="sdds-form-label">
                                    {t("TS_core:TranslationsAdded")}
                                </label>
                            </div>
                        </div>}
                </div>

                {faultGuideManagement.userAction !== 'view' &&
                    <div>
                        <button className='sdds-btn sdds-btn-secondary sdds-btn-md action-button' title={t('TS_temp:CancelButton')} onClick={() => onCancel(faultGuideManagement.userAction, document)}>{t("TS_core:Cancel")}</button>
                        {/* //For admin users */}
                        {faultGuideManagement.userAction === 'create' && !developerAccess && <button className='sdds-btn sdds-btn-secondary sdds-btn-md action-button' title={t('TS_core:SaveAsDraft')} onClick={() => onSave('Create', 'Draft')}>{t("TS_core:SaveAsDraft")}</button>}
                        {faultGuideManagement.userAction === 'create' && !developerAccess && <button className='sdds-btn sdds-btn-primary sdds-btn-md action-button' title={t('TS_core:SaveAndSubmitForTranslation')} onClick={() => setShowSubmitDialog(true)}>{t("TS_core:SaveAndSubmitForTranslation")}</button>}
                        {faultGuideManagement.userAction === 'edit' && !developerAccess && <button className='sdds-btn sdds-btn-secondary sdds-btn-md action-button' title={t('TS_core:SaveAsDraft')} onClick={() => onSave('Update', 'Draft')}>{t("TS_core:SaveAsDraft")}</button>}
                        {faultGuideManagement.userAction === 'edit' && !developerAccess && <button className='sdds-btn sdds-btn-primary sdds-btn-md action-button' title={t('TS_core:SaveAndSubmitForTranslation')} onClick={() => setShowSubmitDialog(true)}>{t("TS_core:SaveAndSubmitForTranslation")}</button>}

                        {/* //Only for Developers */}
                        {faultGuideManagement.userAction === 'create' && developerAccess && <button className='sdds-btn sdds-btn-primary sdds-btn-md action-button' title={t('TS_core:Save')} onClick={() => onSave('Create', 'SubmittedForTranslation')}>{t("TS_core:Save")}</button>}
                        {faultGuideManagement.userAction === 'edit' && developerAccess && <button className='sdds-btn sdds-btn-primary sdds-btn-md action-button' title={t('TS_core:Update')} onClick={() => onSave('Update', translated ? 'Translated' : 'SubmittedForTranslation')}>{t("TS_core:Update")}</button>}

                    </div>
                }
                {showDeleteDialog && <ConfirmationDialog title={t("TS_core:ConfirmDeleteTitle")}
                    confirmationMsg={t("TS_core:ConfirmDeleteMessage")}
                    onAction={() => { onDelete(); setShowDeleteDialog(false); }}
                    primaryActionBtn={t("TS_core:Deregister")}
                    secondaryActionBtn={t("TS_core:Cancel")}
                    isDelete={true}
                    onClose={() => setShowDeleteDialog(false)} />}

                {showSubmitDialog && <ConfirmationDialog title={t('TS_temp:ConfirmSubmit')}
                    confirmationMsg={t('TS_temp:SubmitForTranslationMessage')}
                    onAction={() => { faultGuideManagement.userAction === 'create' ? onSave('Create', 'SubmittedForTranslation') : onSave('Update', 'SubmittedForTranslation'); setShowSubmitDialog(false); }}
                    primaryActionBtn={t("TS_core:SubmitForTranslation")}
                    secondaryActionBtn={t("TS_core:Cancel")}
                    onClose={() => setShowSubmitDialog(false)} />}
            </div>
        }
    </>)
}
export default FaultGuideForm;